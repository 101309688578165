<template>
    <v-sheet class="pt-3 px-3">
        <v-sheet elevation="2" class="rounded-xl pa-3">

            <div class="headline mb-3">
                <v-icon color="red" v-if="dp.otrosFechaDefuncion">mdi-skull</v-icon> {{dp.pacienteId}} {{dp.nombreCompleto}} ({{dp.fechaNacimiento}} <span v-if="dp.otrosFechaDefuncion">- {{dp.otrosFechaDefuncion}}</span>)
            </div>

            <v-row class="pa-3">
                <v-col
                    v-for="(itm, id) in personalesMain"
                    :key="id"
                    cols="12"
                    sm="6"
                    md="4"
                    lg="3"
                >
                    <v-text-field
                        v-model="dp[itm.value]"
                        :label="itm.label"
                        dense
                        readonly
                    />
                </v-col>
            </v-row>

            <v-tabs
                v-model="tab"
                grow
                show-arrows
                background-color="primary"
                slider-size=10
            >
                <v-tab
                    v-for="(itab, idt) in tabs"
                    :key="idt"
                >
                    {{itab.label}}
                </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">
                <v-tab-item
                    v-for="(itab2, idt2) in tabs"
                    :key="idt2"
                >
                    <component
                        :is="itab2.component"
                        :dp='dp'
                        :tab="tab"
                        :esteTab="idt2"
                    />
                </v-tab-item>
            </v-tabs-items>

        </v-sheet>
    </v-sheet>
</template>

<script>
export default {
    mounted(){
        this.cargaPaciente()
    },
    components:{
        'paciente-datos-generales':()=>import('@/components/pacientes/PacienteDatosGenerales'),
        'paciente-internamientos':()=>import('@/components/pacientes/PacienteInternamientos'),
        'paciente-consulta':()=>import('@/components/pacientes/PacienteConsultas'),
        'paciente-notas':()=>import('@/components/pacientes/PacienteNotas'),
        'paciente-indicas':()=>import('@/components/pacientes/PacienteIndicas'),
        'paciente-hc':()=>import('@/components/pacientes/PacienteHistoriaClinica'),
        'paciente-cirugias':()=>import('@/components/pacientes/PacienteCirugias'),
        'paciente-archivos':()=>import('@/components/pacientes/PacienteArchivos'),
    },
    data:()=>({
        dp:{},
        tab:0,

        personalesMain:[
            {label:'C.U.R.P.', value:'curp'},
            {label:'Folio de Cert. Nac.', value:'folioNac'},
            {label:'Derechohabiencia', value:'derechohabienciaNom'},
            {label:'Folio Derechohab.', value:'folioDerechohabiencia'},
            {label:'Edad', value:'edad'},
            {label:'Sexo', value:'sexo'},
            {label:'Responsable', value:'responsable'},
            {label:'Parentesco', value:'parentescoResponsableNom'},
            {label:'Teléfono', value:'telefonoContacto'},
        ],

        tabs:[
            {label:'Datos generales', component:'paciente-datos-generales'},
            {label:'Internamientos', component:'paciente-internamientos'},
            {label:'Citas C.E.', component:'paciente-consulta'},
            //{label:'Padecimientos', component:'-'},
            {label:'Notas', component:'paciente-notas'},
            {label:'Indicaciones', component:'paciente-indicas'},
            {label:'Historia clínica', component:'paciente-hc'},
            //{label:'Estudios S.E:', component:'-'},
            //{label:'Referencias', component:'-'},
            {label:'Cirugias', component:'paciente-cirugias'},
            {label:'Documentos', component:'paciente-archivos'},
        ],
    }),

    methods:{
        async cargaPaciente(){
            try{
                const req = await this.$http({
                    url:'/pacientes/listV2',
                    method:'POST',
                    params:{
                        id:this.$route.params.id
                    }
                })
                this.dp = req.data
            }catch(err){
                this.$store.dispatch('security/revisaError',err)
            }
        },
    },

}
</script>

<style>

</style>